<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-none d-lg-flex p-0" lg="8" md="6">
        <SplashScreen />
      </b-col>
      <!-- Login-->
      <b-col class="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4">
        <b-col class="px-xl-2 mx-auto" lg="12" md="6" sm="8">
          <b-card-title class="font-weight-bold mb-1" title-tag="h2"> MyFlight Connect </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation" #default="{ invalid, validate }">
            <b-form class="auth-login-form mt-2" @submit.prevent="validate().then(submitCredentials)">
              <!-- email -->
              <b-form-group :label="$t('login.email')" label-for="login-email">
                <validation-provider #default="{ errors }" class="validation-required" name="Email" rules="required|email">
                  <b-form-input id="login-email" v-model.trim="credentials.email" :state="errors.length > 0 ? false : null" name="login-email" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div>
                  <label class="d-flex justify-content-between col-12 p-0" for="login-password">
                    {{ $t('login.password') }}
                    <b-link :to="{ name: 'forgot-password' }">
                      <span> {{ $t('login.forgot_password') }}</span>
                    </b-link>
                  </label>
                </div>
                <validation-provider #default="{ errors }" :name="$t('login.password')" class="validation-required" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null" class="input-group-merge">
                    <b-form-input
                      id="login-password"
                      v-model.trim="credentials.password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button :disabled="invalid" block type="submit">
                {{ $t('action.sign_in') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.new_on_platform') }}</span>
              <b-link :to="{ name: 'register' }">
                <span> {{ $t('login.create_an_account') }}</span>
              </b-link>
            </div>
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact' }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapState } from 'vuex'
import SplashScreen from '@/views/base/SplashScreen.vue'

export default {
  components: {
    SplashScreen,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      credentials: { password: '', email: '', organizationToken: this.$route.query.token || null },
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['login']),

    submitCredentials() {
      this.login(this.credentials)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
